import { Label, Paragraph, SearchIcon, Spacer } from '@qasa/qds-ui'
import { Fragment } from 'react'
import { styled } from '@qasa/ui/web'

import { VisuallyHidden } from '../../visually-hidden'

import { AutocompleteInput, AutocompleteBox, ClearButton } from './autocomplete-input'
import { AutocompleteListbox } from './autocomplete-listbox'
import { useAutocomplete } from './use-autocomplete'

const AutocompleteWrapper = styled('div')({
  position: 'relative',
})

const SearchIconWrapper = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing['4x'],
}))

export type OptionToLabel<TOption> = (option: TOption | null) => string
export type OptionToSublabel<TOption> = (option: TOption | null) => string | undefined | null

export type AutocompleteProps<TOption> = {
  value: TOption[]
  options: TOption[]
  onChange: (nextValue: TOption[]) => void
  inputValue: string
  onInputValueChange: (inputValue: string) => void
  optionToLabel: OptionToLabel<TOption>
  optionToSublabel?: OptionToSublabel<TOption>
  placeholder?: string
  label: string
  isLabelVisuallyHidden?: boolean
  maxOptions?: number
  isLoading?: boolean
  errorMessage?: string
}
export function Autocomplete<TOption>({
  options,
  value,
  label,
  isLabelVisuallyHidden = false,
  placeholder,
  optionToLabel,
  optionToSublabel,
  onChange,
  maxOptions = 3,
  inputValue,
  onInputValueChange,
  isLoading = false,
  errorMessage,
}: AutocompleteProps<TOption>) {
  const {
    isOpen,
    selectedOptions,
    highlightedIndex,
    reset,
    getLabelProps,
    getSelectedItemProps,
    removeSelectedItem,
    getInputProps,
    getDropdownProps,
    getMenuProps,
    getItemProps,
  } = useAutocomplete<TOption>({
    options,
    value,
    onChange,
    inputValue,
    onInputValueChange,
    optionToLabel,
  })

  const hasSelectedOptions = Boolean(selectedOptions.length)
  const hasSelectedOptionsOrInputValue = Boolean(selectedOptions.length) || Boolean(inputValue)

  const LabelWrapper = isLabelVisuallyHidden ? VisuallyHidden : Fragment
  const ListBoxWrapper = isOpen ? Fragment : VisuallyHidden

  return (
    <div>
      <AutocompleteWrapper>
        <LabelWrapper>
          <Label {...getLabelProps()}>{label}</Label>
          <Spacer size="2x" />
        </LabelWrapper>
        <AutocompleteBox hasError={Boolean(errorMessage)}>
          {!hasSelectedOptions && (
            <SearchIconWrapper>
              <SearchIcon size={20} />
            </SearchIconWrapper>
          )}
          <AutocompleteInput
            {...{
              getInputProps,
              placeholder,
              optionToLabel,
              selectedOptions,
              maxOptions,
              getSelectedItemProps,
              getDropdownProps,
              isComboboxOpen: isOpen,
              removeSelectedItem,
            }}
          />
          {hasSelectedOptionsOrInputValue && (
            <ClearButton
              onClick={() => {
                reset()
                onInputValueChange('')
              }}
            />
          )}
        </AutocompleteBox>

        <ListBoxWrapper>
          <AutocompleteListbox
            {...{
              inputValue,
              isLoading,
              isOpen,
              optionToLabel,
              options,
              optionToSublabel,
              getItemProps,
              getMenuProps,
              highlightedIndex,
            }}
          />
        </ListBoxWrapper>
      </AutocompleteWrapper>
      {errorMessage && (
        <Paragraph size="sm" color="negative">
          {errorMessage}
        </Paragraph>
      )}
    </div>
  )
}
